import { useNavigate } from "react-router-dom";
import { auth } from "../auth/firebaseConfig";
import { getChallengerByToken } from "../services/challengeService";

//export const API_URL: string = 'https://157.245.61.194/v1';
export const API_URL: string = 'https://challexa.com/v1';
//export const API_URL: string = 'http://localhost:8000/v1';
export const API_KEY: string = process.env.REACT_APP_API_KEY as string;
export const API_AUTH_DOMAIN: string = process.env.REACT_APP_AUTH_DOMAIN as string;
export const API_PROJECT_ID: string = process.env.REACT_APP_PROJECT_ID as string;
export const API_STORAGE_BUCKET: string = process.env.REACT_APP_STORAGE_BUCKET as string;
export const API_MESSAGING_SENDER_ID: string = process.env.REACT_APP_MESSAGING_SENDER_ID as string;
export const API_APP_ID: string = process.env.REACT_APP_APP_ID as string;
export const BASICNAME: string = 'challengerusername';
export const BASICPASS: string = 'challengerpassword';
export const MountainText: string = '[ /\\\\ ]';
export const BrandTextCapitalLetter: string = 'CHALLEXA';
export const BrandText: string = 'challexa';
export const BrandbackgroundColor: string = '#212121';
export const API_HOWTO_URL_PREFIX: string = 'https://challexa-how-to-bucket.s3.ap-southeast-1.amazonaws.com/';
export const API_USER_URL_PREFIX: string = 'https://challexa-user-bucket.s3.ap-southeast-1.amazonaws.com/';
export const API_CHALLEXA_IMAGE_URL: string = 'https://challexa-user-bucket.s3.ap-southeast-1.amazonaws.com/256.png';

export function getAuthConfig() {
    const authString = `${BASICNAME}:${BASICPASS}`;
    const encodedAuthString = btoa(authString);
    //console.log(authString)
    return {
      headers: {
        'Authorization': `Basic ${encodedAuthString}`,
        "auth-token": getAuthToken()
      }
    };
  }

  export const saveAuthToken = (token: string) => {
    localStorage.setItem('quei3', token);
  };
  
  export const getAuthToken = (): string | null => {
    return localStorage.getItem('quei3');
  };
  
  export const removeAuthToken = () => {
    localStorage.removeItem('quei3');
  };

  
export const saveChallenger =  (challenger: any) => {
  console.log(challenger)
  saveAuthToken(challenger.jwtToken);
  localStorage.setItem('challenger', JSON.stringify(challenger));
};

export const getChallenger = async() => {
  let challenger = localStorage.getItem('challenger');
  if(!challenger){
    const token = getAuthToken();
    if(token){
      challenger = await getChallengerByToken(token);
      saveChallenger(challenger);
    }
  }
  return challenger ? JSON.parse(challenger) : null;
};

export const getChallengerAgain = async() => {
  let challenger=null;
  const token = getAuthToken();
  if(token){
    challenger = await getChallengerByToken(token);
    saveChallenger(challenger);
    //console.log(challenger)
    return challenger;
  }else{
    return null;
  }
  
};

export const removeChallenger = () => {
  localStorage.removeItem('challenger');
};

export const handleLogout = () => {
  console.log("handleLogout");
  removeAuthToken();
  removeChallenger();
  window.location.href = '/';
};
