import { IconButton, Tooltip, Box, Card, CardContent, Chip, styled, Typography, Alert, DialogContent, DialogTitle, Dialog, DialogActions, Button, Snackbar,  Divider } from '@mui/material';
import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStatusColor, getDifficultyColor,categoryList } from '../utils/siteHelpers';
import {ChallengeCardInterface} from '../utils/ObjectInterface';
import VideoCarousel from './VideoCarousel';
import ReplySharpIcon from '@mui/icons-material/ReplySharp';
import PeopleIcon from '@mui/icons-material/People';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { savingchallenger, takeChallenge} from '../services/challengeService';
import {getAuthToken} from '../config/config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig'; 

interface AuthModalProps {
  onLoginSuccess: () => void;
  }


  const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    width: '100%', 
    display: 'flex',
    background: '#282c34',
    color: 'white',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
    
  }));

  
  
  
  const ChallengeCard: React.FC<ChallengeCardInterface> = ({ _id, title, challengrstatus, description, duration_text, difficulty,visibility,pagetype,allow_edit,promote_videolist,invite_link,completed,ongoing,joined,category, searchcategory,searchdifficulty}) => {
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openShareSnackbar, setShareOpenSnackbar] = useState(false);
    const [invite_link_show,setInviteLink] = useState<any>(null);
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
    const [openDialog, setOpenDialog] = useState(false);
    const completed_count = completed ? completed.count  : 0;
    const ongoing_count = ongoing ? ongoing.count  : 0;
    const participants = completed_count + ongoing_count;
    const [error, setError] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
      setIsLoggedIn(!!getAuthToken());
      
    }, []);
    useEffect(() => {
      if (isLoggedIn) {
        // This will run whenever isLoggedIn changes to true
        //console.log('User is logged in');
      }
    }, [isLoggedIn]);
    

    const handleGoogleSignIn = async () => {
      if(!isLoggedIn){
        const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        // You can access the user info here
        const user:any = result.user;
        setIsLoggedIn(true);
        if(user?.reloadUserInfo ){
          
            
          await savingchallenger(user.reloadUserInfo);
          //console.log('User Info:', user);
          return true;
        }else{
          console.error('Error during Google sign-in');
          return false;
        }
        
      } catch (error) {
        console.error('Error during Google sign-in:', error);
        return false;
      }
    }else{
      return true;
    }
      
  };
  
    
    const copyToClipboard = () => {
      console.log(invite_link)
        if(invite_link){
          navigator.clipboard.writeText(invite_link)
            .then(() => {
              console.log('Link copied to clipboard!');
              setInviteLink(invite_link)
              setShareOpenSnackbar(true);
            })
            .catch(err => {
              console.error('Failed to copy: ', err);
            });
      }
    }
    const handleDialogOpen = () => {
      setOpenDialog(true);
    };
  
    const handleDialogClose = () => {
      setOpenDialog(false);
    };
  
    const handleDateChange = (date: Dayjs | null) => {   
      setStartDate(date);
    };
    
    const handleConfirm = async () => {
      handleDialogClose();
      //console.log(isLoggedIn)
      let r = false;
      if(!isLoggedIn){
        r = await handleGoogleSignIn();
        //console.log(r);
      }
      //console.log(r);
      if((r || isLoggedIn) && _id && startDate){
        try {
          //if()
          const challenge = await takeChallenge(_id, startDate.toDate());
          if(challenge){
            setOpenSnackbar(true);
            if(r){
              window.location.href = `/challenge/info?_id=${_id}&p=joined`;
            }else{
              navigate(`/challenge/info?_id=${_id}&p=joined`);
            }
            
          }
        } catch (err) {
          setError("Failed to start the challenge. Please try again.");
          console.error(err);
        }
      }  
    };
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
    return (
      <>
      <StyledCard>
      <CardContent   sx={{ flexGrow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb:  2}}>
          <Typography 
          onClick={() => navigate(`/challenge/info?_id=${_id}&p=${pagetype}`)} 
          variant="subtitle2" component="div"  sx={{ flexGrow: 1, mr: 1 }}>
          {title} 
          {/* {allow_edit && ( // Conditionally render the Edit icon
            <EditIcon 
              onClick={(e:any) => { 
                e.stopPropagation(); 
                navigate(`/challenge/edit?_id=${_id}&p=${pagetype}`); 
              }} 
              sx={{ cursor: 'pointer', color: 'primary.main' }} // Style the icon
            />
          )} */}
          </Typography>
          <Chip
          label={difficulty}
          color={getDifficultyColor(difficulty)}
          size="small"
          sx={{
            width: 55, // Fixed width in pixels (adjust as needed)
            display: 'flex',
            justifyContent: 'center', // Center the text inside the Chip
            whiteSpace: 'nowrap', // Prevent text from wrapping
            overflow: 'hidden', // Hide any overflowing text
            textOverflow: 'ellipsis', // Add ellipsis if text overflows
            color: 'black'
          }}
          onClick={
            (e)=>{
              if(searchdifficulty){
                return searchdifficulty(difficulty);
              }
              
            }
          }
          />
      </Box>
      <Typography
      onClick={() => navigate(`/challenge/info?_id=${_id}&p=${pagetype}`)} 
      variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        {description.slice(0, 120)}...<strong>See more</strong>
      </Typography>
      {visibility && pagetype === 'mine' && 
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        Visibility: {visibility} 
      </Typography>
      }
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
          🗓️ {duration_text}
          </Typography>
          
      </Box>
      
      </CardContent>
      
      { promote_videolist && promote_videolist.length > 0 && 
      <CardContent>
{/*         <VideoCarousel videoUrls={promote_videolist} muted={true}/> 
 */}      

        </CardContent>
      }
      
        <CardContent  sx={{marginBottom: "-10px"}} >
          {category && searchcategory && (
            <Box>
              <Chip onClick={
                (e)=>{
                  return searchcategory(category);
                }
              }  label={categoryList.filter((e)=>e.value === category)[0].text} variant="outlined"  />
            </Box>
          )}
          
  <Box display="flex" justifyContent="space-between" alignItems="center">
    {/* Participants */}
    <span><PeopleIcon sx={{ verticalAlign: 'bottom' }} />
    {" " + participants}</span>

    {/* Divider */}
   {/*  <Divider orientation="vertical" flexItem sx={{ mx: 2,backgroundColor:'grey' }} /> */}

    {/* Join button */}
    {!joined && (
      <>
        <Button onClick={handleDialogOpen} variant="text">
          Join
        </Button>
       {/*  {invite_link && <Divider orientation="vertical" flexItem sx={{ mx: 2 ,backgroundColor:'grey'}} />} */}
      </>
    )}
    
    {joined && (
      <>
        <Button onClick={() => navigate(`/challenge/info?_id=${_id}&p=${pagetype}`)}  variant="text">
          {challengrstatus}
        </Button>
       {/*  {invite_link && <Divider orientation="vertical" flexItem sx={{ mx: 2 ,backgroundColor:'grey'}} />} */}
      </>
    )}


    {/* Invite link */}
    {invite_link && (
      <Tooltip title={invite_link}>
        <IconButton onClick={copyToClipboard}>
          <ReplySharpIcon sx={{ transform: 'rotateY(180deg)' }} />
        </IconButton>
      </Tooltip>
    )}
  </Box>

  {/* Snackbar for invite link */}
  {invite_link_show && (
    <Snackbar open={openShareSnackbar} autoHideDuration={10000} onClose={() => setShareOpenSnackbar(false)}>
      <Alert onClose={() => setShareOpenSnackbar(false)} severity="success" sx={{ width: '90%' }}>
        Copied: {invite_link_show}
      </Alert>
    </Snackbar>
  )}
</CardContent>
  </StyledCard>
  <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          You're in! Challenge accepted!
        </Alert>
      </Snackbar>
  <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="start-challenge-dialog-title">
        <DialogTitle id="start-challenge-dialog-title">Ready to start your challenge?</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleDateChange}
              minDate={dayjs()}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Let's Go!
          </Button>
        </DialogActions>
      </Dialog>
      </>
  );
};

export default ChallengeCard;
